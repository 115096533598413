//colors: orange: #D18F18 | darkGreen: #456864

.home-page {
  width: 100%;
  position: relative;
  max-width: 500px;
  min-height: 100vh;
  margin-bottom: 70px;
  background-image: url("../../assets/images/MainIMG.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-top: 100px;

  .wrapper {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    overflow: hidden;
    top: 20px;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  .imglogo{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .developer-information {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 0.5rem;
    color: #989898;
    text-align: center;
    direction: rtl;
    margin-top: -40px;

    h1 {
      color: black;
      text-align: left;
      margin-bottom: 1rem;
      font-family: 'Rubik', sans-serif;
      font-size: 2rem;
      font-weight: 600;
      text-align: center;
    }

    .location {
      font-size: 18px;
      font-family: 'Rubik', sans-serif;

      &::before {
        content: url('../../assets/images/geo-alt.svg');
      }
    }

    .phone-number {
      font-size: 18px;
      font-family: 'Rubik', sans-serif;

      &::before {
        content: url('../../assets/images/telephone.svg');
        transform: scaleX(-1);
      }
    }

    .insta {
      font-size: 18px;
      font-family: 'Rubik', sans-serif;

      &::before {
        content: url('../../assets/images/insta.svg');
      }
    }
  }

  .goBack-wrapper {
    padding: 0%;
    border: 0;
  }

  .go-Back {
    box-shadow: rgba(0, 0, 0, 0.3) 0 3px 8px;
    box-sizing: border-box;
    position: fixed;
    left: 1rem;
    top: 1rem;
    width: 40px;
    background-color: white;
    border-radius: 20px;
    z-index: 1;
    display: block;
  }

  &:first-child {
    div {
      background-color: white;
      border-radius: 45px;
    }
  }

  .order-display {
    display: flex !important;
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: rgb(247, 144, 108) !important;
    border-radius: 50px 50px 0 0 !important;
    position: fixed !important;
    bottom: 0 !important;
    box-sizing: border-box !important;
    padding: 1rem !important;

    a {
      text-decoration: none;
    }

    span {
      color: white !important;
      font-size: 2rem;
    }
  }
}
