.catagory-container {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 0%;

  h1 {
    position: absolute;
    white-space: nowrap;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 125px;
    border-radius: 25px;
    filter: brightness(75%);
  }
}


.catagory-container:hover{
  img{
    filter: brightness(50%);
  }
}