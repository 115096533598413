.meals-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.category-name {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: 20px 0;
  color: #fff; 
}
.breakText{
  font-size: 25px;
  text-align: center;
  display: block;
  margin: -20px auto 20px;
}

.itemList {
  list-style: none;
  padding: 0;
}

.item {
  margin-bottom: 20px;
  text-align: center; 
}

.itemName {
  margin: 0;
  font-size: 28px;
  color: #456864;
}

.itemPrice {
  font-size: 22px;
  color: #D18F18; 
}
.item2 {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  margin-bottom: 10px;
}

.itemDetails {
  display: flex;
  align-items: center; /* Center items vertically */
}

.itemName {
  margin-left: 20px; /* Add space between item name and price */
}


.itemDetails {
  display: flex; /* Use flexbox layout */
  align-items: center; /* Align items vertically in the center */
}

.itemName {
  margin-right: 10px; /* Add space between item name and price */
}

.separator {
  border: none;
  border-top: 1px solid #456864; /* Adjust the color and thickness as needed */
  margin: 10px 0; /* Adjust the margin as needed */
}
