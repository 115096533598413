.type-container {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 1rem;

  h1 {
    color: black;
    text-align: left;
    margin-bottom: 1rem;
    font-family: 'Rubik', sans-serif;
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
  }
}
