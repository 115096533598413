.meal-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #456864;
  box-shadow: 0px 0px 9px 0px #456864;
-webkit-box-shadow: 0px 0px 9px 0px #456864;
-moz-box-shadow: 0px 0px 9px 0px #456864;
  padding: 1rem;
  align-items: center;

  .image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      box-sizing: border-box;
      position: relative;
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 30px;
    }
  }

  .name-container {
    display: flex;
    justify-content: flex-start;

    span {
      color: #456864;
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 20px;
      align-items: center;
    }
  }

  .price-and-order-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .counter {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 1rem;

      span {
        font-size: 24px;
        font-weight: 500;
        color: #f7c96c;
        width: 1rem;
        text-align: center;
      }

      .plus2 {
        background-color: white;
      }

      .minus {
        background-color: white;
      }
    }

    .span1 {
      font-size: 24px;
      font-weight: 500;
      color: #d18f18;

      &::before {
        content: "₪";
      }
    }

    img {
      width: 40px;
      background-color: #456864;
      border-radius: 20px;
    }
  }

  .discription-container{
    text-align: center;
  }
}

.hide {
  display: none;
}
